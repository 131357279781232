import './ReportSelectorModal.scss';
import { documentsApi } from '../../../api/documentsApi';
import { Alert } from '../../../api/utils';
import { Modal } from 'components/InteractiveUIControls/Modal/Modal';
import { Svgicon } from 'components/Svgicon/Svgicon';
import environment from 'config/environment';
import { ModalActivationContext } from 'contexts';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import {
  type FC,
  useContext,
} from 'react';
import { useSelector } from 'react-redux';
import { type DocumentsStoreState } from 'store/slices';
import {
  type Report,
} from 'types';

const BASE_FMS_URL = environment.fmsLoginUrl;

export const getFMSUrl = async (reportId: string) => {
  const token = await documentsApi.getReportToken({ reportId });
  const FMSUrl = `${BASE_FMS_URL}/published-investor-report?token=${token}`;
  return FMSUrl;
};

export const ReportSelectorModal: FC = () => {
  const {
    reports,
  } = useSelector<{ documents: DocumentsStoreState, }>(
    (state) => state.documents,
  ) as DocumentsStoreState;

  const { togglePerformanceReportsModal } = useContext(ModalActivationContext);

  const onViewReport = async (fundReport: Report) => {
    if (!fundReport._id) {
      Alert('Please select a reporting period to view!');
      return;
    }

    const newWindow = window.open('', '_blank');
    const url = await getFMSUrl(fundReport._id);
    if (newWindow) {
      newWindow.location.href = url;
    }

    togglePerformanceReportsModal(false);
  };

  return <Modal data-test='performance-reports-modal-container'>
    <div className='main-white-card relative h-full  !w-[25.375rem] rounded-none px-7 pb-6 pt-11 dt:size-auto dt:!h-[31.5rem] dt:rounded-medium dt:px-[0.875rem]'>
      <span
        className='absolute right-[1.25rem] top-[0.375rem] z-[1010] size-1.5 cursor-pointer dt:right-[0.375rem]'
        data-test='performance-report-modal-close-button'
        onClick={() => togglePerformanceReportsModal(false)}
      >
        <Svgicon className='rounded-[50%] bg-main-700 text-white-999' id='close-1f' />
      </span>
      <div className='scrollContainer flex h-full flex-col overflow-y-scroll'>
        {reports?.map((report, index) =>
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${report.period}-${index}`}><p className='m-0 text-font-3 font-normal text-black-700' >{moment(report.period).format('YYYY-MM-DD')}</p>
            <div className='my-[0.5rem]'>
              {report.reports.map((fundReport) =>
                <div className='fund-container mr-[0.25rem] flex h-10 cursor-pointer items-center justify-between rounded-full pl-[1rem] pr-[0.75rem]' data-test={`report-${fundReport.fundName}-${moment(report.period).format('YYYY-MM-DD')}`} key={fundReport._id} onClick={() => onViewReport(fundReport)}>
                  <p className='m-0 text-font-2 text-black-999'>{fundReport.fundName}</p>
                  <Svgicon className='icon-arrow' color={'#B2B2B3'} id='arrow-right-3f' />
                </div>)}
            </div>
          </div>)}
      </div>
    </div>
  </Modal>;
};
