import { type TreeNode } from 'components/TreeMultiSelector/TreeMultiSelector';
import { type NestedOrganization } from 'types';

export const getPeriodOptions = (organization: NestedOrganization): TreeNode[] => {
  const yearMap = organization?.funds?.reduce((map, fund) => {
    for (const acc of fund.accounts) {
      for (const { year,
        quarters } of acc.yearsAndQuarters) {
        if (!map.has(year)) {
          map.set(year, new Set());
        }

        for (const quarter of quarters) {
          map.get(year)?.add(quarter);
        }
      }
    }

    return map;
  }, new Map<number, Set<string>>()) ?? new Map<number, Set<string>>();

  return Array.from(yearMap.entries())
    .sort(([
      yearA,
    ], [
      yearB,
    ]) => yearB - yearA)
    .map(([
      year,
      quarters,
    ]) => {
      const sortedQuarters = Array.from(quarters)
        .sort((a, b) => b.localeCompare(a));

      return {
        children: sortedQuarters.map((quarter) => ({
          id: `${year}/${quarter}`,
          name: `${quarter} ${year}`,
        })),
        id: year.toString(),
        name: year.toString(),
      };
    });
};
